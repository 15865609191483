<template>
  <template v-if="!restricted">
    <component :is="components[page.pageLayout]" :page="page" />
  </template>
  <div v-else class="flex justify-center">
    <Spinner :spin="true" size="large" class="my-4" />
  </div>
</template>

<script setup lang="ts">
  import { defineAsyncComponent } from "vue"
  import { AlchemyPage } from "~/mixins/alchemyVue"

  const { api } = useApi()
  const authStore = useAuthStore()
  const router = useRouter()

  const props = defineProps<{
    page: AlchemyPage
  }>()

  const restricted = ref(props.page.restricted)

  onBeforeMount(async () => {
    if (props.page.restricted) {
      try {
        const response = await api<any>("/cs/api/session")
        if (response?.user) {
          authStore.setUser(response.user)
          if (response.user?.featureFlags?.alchemyRestrictedAccess) {
            restricted.value = false
          } else {
            await router.push("/404/")
          }
        } else {
          await router.push("/login/")
        }
      } catch (error) {
        authStore.resetState()
        await router.push("/login/")
      }
    }
  })

  const components = {
    fragrance_note_glossary_page: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/fragrance_note_glossary_page.vue"),
    ),
    content_page: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/content_page.vue"),
    ),
    newsletter_archive: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/newsletter_archive.vue"),
    ),
    wax_guide: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/wax_guide.vue"),
    ),
    thumbnail_index: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/thumbnail_index.vue"),
    ),
    taxon_page: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/taxon_page.vue"),
    ),
    ifra_listing: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/ifra_listing.vue"),
    ),
    learning_overview: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/learning_overview.vue"),
    ),
    learning_recipe: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/learning_recipe.vue"),
    ),
    learning_guide: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/learning_guide.vue"),
    ),
    troubleshooting_guide: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/troubleshooting_guide.vue"),
    ),
    fragrance_oil_finder: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/fragrance_oil_finder.vue"),
    ),
    material_supply_calculator: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/material_supply_calculator.vue"),
    ),
    wick_guide: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/wick_guide.vue"),
    ),
    calculator_page: defineAsyncComponent(
      () => import("~/alchemy/page_layouts/calculator_page.vue"),
    ),
  }
</script>
